// BASE
$color: #000; // black
	$dark-color: #fff; // white for dark mode

$color2: #fff; // white
	$dark-color2: #000; // black for dark mode

// GRAY
//$color3: #000; // not used
	//$dark-color3: #fff;

$color4: #858995; // infoContainerButton
	$dark-color4: #a8a8a8;

$color5: #cdcdcd; // inner lines in table
	$dark-color5: #777;

$color6: #f3f4f7; // background widget & game f3f4f7 f0f2f5
	$dark-color6: #38393a; // ex 404040

// YELLOW
$color7: #ff800d; // orange
$color8: #f2be00; // yellow

// GREEN
$color9: #10d100; // green
	$dark-color9: #10d100;

$color10: #18d39e; // mint

// RED
$color11: #ff003d; // red
$color12: #ff0096; // pink

// BLUE
$color13: #8e34f7; // purple
$color14: #0067fb; // blue
$color15: #00c1ff; // light-blue
$color16: #005ce6; // dark-blue

// OTHER
$font-size: 20px; // main
$font-size2: 18px; // small
$font-size3: 28px; // h2 & h3 in text
$font-size4: 28px; // modal window title
$font-size5: 34px; // large
$font-size6: 16px; // x-small
$font-size7: 40px; // x-large

$opacity: .7; // link opacity .75 & .9
	$dark-opacity: .85;

$opacity2: .5; // text opacity

$widget-width: 288px; // width of widget
$min-width: 320px; // minimum width of table or result’s div
$max-height: 12rem; // height of result’s textarea

$border-radius: 16px; // dropdown menu, modal windows, images
$border-radius2: 3rem; // primary buttons & pagination

$background: rgba(#fff, 1); // options overlay, modal windows overlay
	$dark-background: rgba(#000, 1);

$box-shadow: 0 2px 6px rgba(#000, .15); // all dropdown

// MIXIN
// text-decoration
	$text-decoration: underline;
	$text-decoration-style: dotted;
	$text-decoration-thickness: 1px;
	$text-underline-offset: 5.5px;

@mixin text-decoration {
	text-decoration: $text-decoration;
	text-decoration-style: $text-decoration-style;
	text-decoration-thickness: $text-decoration-thickness;
	text-underline-offset: $text-underline-offset;
}

// font-awesome
	$fa-family: "Font Awesome 6 Pro";
	$fa-display: inline-block;
	$fa-position: absolute;
	$fa-font-size: inherit;
	$fa-moz-osx-font-smoothing: grayscale;
	$fa-webkit-font-smoothing: antialiased;

@mixin font-awesome {
	font-family: $fa-family;
	display: $fa-display;
	position: $fa-position;
	font-size: $fa-font-size;
	-moz-osx-font-smoothing: $fa-moz-osx-font-smoothing;
	-webkit-font-smoothing: $fa-webkit-font-smoothing;
}