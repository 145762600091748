@import url(../css/angular-csp.css);
@import url(//fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;500;600;700;800&display=swap);
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

body,
html {
  height: 100%; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

img, fieldset, a img {
  border: none; }

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="submit"],
button {
  cursor: pointer; }
  input[type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border: 0; }

textarea {
  overflow: auto; }

input, button {
  margin: 0;
  padding: 0;
  border: 0; }

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none; }

ul, ol {
  list-style-type: none; }

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

@font-face {
  font-family: 'SansSerifBldFLF';
  src: url("../font/ssbf.woff2") format("woff2"), url("../font/ssbf.woff") format("woff");
  font-display: swap; }

html {
  height: auto;
  position: relative; }

body {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  background: #fff; }
  body.wrapper {
    height: 100vh !important; }

*:focus {
  outline: none; }

::selection {
  background: #000;
  color: #fff; }

a[disabled],
:disabled {
  pointer-events: none; }

a {
  color: #000;
  display: flex;
  text-decoration: none;
  cursor: pointer; }
  a.inactive {
    pointer-events: none;
    color: #000; }
    a.inactive:hover {
      color: inherit;
      opacity: 0.7; }
  a:hover {
    opacity: 0.7 !important; }

b,
strong {
  font-weight: 600; }

button {
  all: unset;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  align-items: center; }
  button:hover {
    opacity: 0.7; }
  button [disabled],
  button :disabled {
    pointer-events: none; }
  button.inactive {
    pointer-events: none;
    color: #000; }
    button.inactive:hover {
      color: inherit;
      opacity: 0.7; }

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; }
  .wrapper__container {
    flex: 1; }

.container {
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1200px; }
  .container__title {
    text-align: center;
    margin-top: 2rem;
    font-size: 34px;
    font-weight: 600; }
  .container__2x {
    text-align: center; }
    .container__2x:empty {
      display: none !important; }
    .container__2x_top {
      margin: 2rem 0 0; }
    .container__2x_bottom {
      margin: 2rem 0; }
    .container__2x a {
      display: flex;
      justify-content: center; }

.submenu__list {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  padding: 1.5rem;
  position: absolute;
  top: 100%;
  z-index: 102;
  margin-top: .5rem;
  right: 25%;
  transform: translate(25%, 0); }

.close__submenu,
.modal__close,
.cookie__close,
.options__close {
  cursor: pointer;
  position: relative; }
  .close__submenu:before,
  .modal__close:before,
  .cookie__close:before,
  .options__close:before {
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    position: absolute;
    font-size: inherit;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: "\f00d";
    font-size: 28px;
    font-weight: 100;
    line-height: 1; }

.checkbox {
  height: 1rem;
  margin: 0;
  vertical-align: top;
  width: 1rem; }

.checkbox + label {
  cursor: pointer; }

.checkbox:not(checked) {
  opacity: 0;
  outline: 0;
  position: absolute; }

.checkbox:not(checked) + label {
  display: block;
  padding: 0 0 0 28px;
  position: relative; }

.checkbox:not(checked) + label:before {
  font-family: "Font Awesome 6 Pro";
  display: inline-block;
  position: absolute;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "\f0c8";
  left: 0;
  top: 50%;
  transform: translate(0, -50%); }

.checkbox:not(checked) + label:after {
  font-family: "Font Awesome 6 Pro";
  display: inline-block;
  position: absolute;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "";
  left: 0;
  top: 50%;
  transform: translate(0, -50%); }

.checkbox:checked + label:after {
  content: "\f14a"; }

.radio {
  height: 1rem;
  margin: 0 5px 0 0;
  vertical-align: top;
  width: 1rem; }
  .radio + label {
    cursor: pointer; }
  .radio:not(checked) {
    opacity: 0;
    position: absolute; }
    .radio:not(checked) + label {
      display: block;
      padding: 0 0 0 1.5rem;
      position: relative; }
      .radio:not(checked) + label:before {
        font-family: "Font Awesome 6 Pro";
        display: inline-block;
        position: absolute;
        font-size: inherit;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        content: '\f111';
        left: 0;
        top: 50%;
        transform: translate(0, -50%); }
      .radio:not(checked) + label:after {
        font-family: "Font Awesome 6 Pro";
        display: inline-block;
        position: absolute;
        font-size: inherit;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        left: 0;
        top: 50%;
        transform: translate(0, -50%); }

.radio:not(checked) + label:hover:after {
  background: none; }

.radio:checked + label:hover:after,
.radio:checked + label:after {
  content: '\f058'; }

.toggle {
  height: 1rem;
  margin: 0;
  vertical-align: top;
  width: 1rem; }

.toggle + label {
  cursor: pointer; }

.toggle:not(checked) {
  display: none; }

.toggle:not(checked) + label {
  display: block;
  padding: 0 0 0 28px;
  position: relative; }

.toggle:not(checked) + label:before {
  font-family: "Font Awesome 6 Pro";
  display: inline-block;
  position: absolute;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "\f111";
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  font-weight: 300; }

.toggle:not(checked) + label:after {
  font-family: "Font Awesome 6 Pro";
  display: inline-block;
  position: absolute;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "";
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  font-weight: 300; }

.toggle:checked + label:before {
  opacity: 0; }

.toggle:checked + label:after {
  content: "\f057"; }

.block__height {
  height: 765px; }

.header {
  border-bottom: 1px solid #e1e4e9;
  padding: 1.5rem 0; }
  .header__container {
    align-items: center;
    display: flex;
    margin: 0 auto;
    padding: 0 1rem;
    max-width: 1200px;
    flex-direction: column;
    gap: 1.5rem; }
  .header__logo {
    text-align: center;
    user-select: none; }
    .header__logo a {
      font-family: 'SansSerifBldFLF', sans-serif;
      font-size: 28px;
      line-height: .75;
      flex-direction: column; }
  .header__info {
    font-size: 16px;
    width: 400px; }
    .header__info-content {
      overflow: hidden;
      padding: .5em;
      position: relative; }
  .header__nav-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem 2rem; }
    .header__nav-list a {
      align-items: center;
      gap: .5rem; }
      .header__nav-list a:hover {
        color: #000; }
    .header__nav-list_inner {
      display: none; }
  .header__nav .nav__content-list {
    gap: 1rem 2rem; }
  .header__toggle .toggle:not(checked) + label:before {
    content: none; }
  .header__toggle .toggle:checked + label:after {
    content: none; }
  .header__toggle .toggle:not(checked) + label {
    padding: 0;
    color: #000;
    display: flex;
    gap: .5rem;
    align-items: center; }
    .header__toggle .toggle:not(checked) + label:hover {
      opacity: 0.7; }
  .header__lang-form {
    display: none; }

.nav {
  margin: 2rem 0 1rem;
  user-select: none; }
  .nav__container {
    display: flex;
    justify-content: center; }
  .nav__content {
    display: flex;
    align-items: center; }
    .nav__content-list {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; }
      .nav__content-list a {
        align-items: center;
        gap: .5rem; }
      .nav__content-list .nav__content-link_disabled[disabled], .nav__content-list .nav__content-link_disabled:disabled {
        opacity: 1;
        pointer-events: fill; }
      .nav__content-list li, .nav__content-list .nav__options {
        white-space: nowrap; }
        .nav__content-list li.has-submenu, .nav__content-list .nav__options.has-submenu {
          position: relative; }
          .nav__content-list li.has-submenu.open-submenu .nav__content-list_inner,
          .nav__content-list li.has-submenu.open-submenu .options, .nav__content-list .nav__options.has-submenu.open-submenu .nav__content-list_inner,
          .nav__content-list .nav__options.has-submenu.open-submenu .options {
            display: flex;
            flex-direction: column;
            gap: 1.5rem; }
          .nav__content-list li.has-submenu.open-submenu .nav__content-list_inner, .nav__content-list .nav__options.has-submenu.open-submenu .nav__content-list_inner {
            right: 50%;
            transform: translate(50%, 0);
            display: flex;
            gap: .5rem;
            flex-direction: column; }
      .nav__content-list_inner,
      .nav__content-list .options {
        display: none; }
        .nav__content-list_inner .options__content-list button,
        .nav__content-list .options .options__content-list button {
          opacity: 1 !important; }
      .nav__content-list_overflow {
        overflow: auto;
        height: 350px; }
    .nav__content-info {
      color: #000;
      display: flex;
      gap: 1rem; }
      .nav__content-info_muted {
        color: #000; }
        .nav__content-info_muted:not([disabled]):hover {
          color: #000; }
      .nav__content-info_block {
        display: flex;
        gap: 5.25px; }
        .nav__content-info_block a {
          color: #000; }
  .nav__options.open-submenu .nav__options-overlay {
    background-color: white;
    bottom: 0;
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 103; }
  .nav__options .options {
    box-shadow: none;
    left: 50%;
    padding: 0;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    z-index: 104;
    margin: 0; }
    .nav__options .options__close {
      position: absolute;
      align-items: baseline;
      top: 0;
      right: 21px; }
      .nav__options .options__close:hover {
        opacity: 0.7; }
    .nav__options .options__content {
      overflow: auto;
      max-height: 290px; }
      .nav__options .options__content input[type="checkbox"] {
        display: none; }
    .nav__options .options__header {
      margin-top: 1.5rem; }
      .nav__options .options__header-title {
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        gap: .5rem; }
    .nav__options .options .checkbox:not(checked) + label:after {
      opacity: 0;
      top: 0;
      transform: none; }
    .nav__options .options .checkbox:not(checked) + label:before {
      top: 0;
      transform: none; }
    .nav__options .options .checkbox:checked + label:before {
      opacity: 0; }
    .nav__options .options .checkbox:checked + label:after {
      opacity: 1; }
    .nav__options .options .toggle:checked + label:before {
      opacity: 0; }
    .nav__options .options .toggle:checked + label:after {
      opacity: 1; }
  .nav__options-meta {
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: #858995;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: 1px;
    text-underline-offset: 5.5px; }

.score {
  display: inline-block; }

.info-container {
  background: #f3f4f7;
  padding: 1rem;
  font-size: 18px;
  margin-top: 1rem; }
  .info-container__item:not(:last-child) {
    padding-bottom: 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid; }
  .info-container__title {
    font-size: 28px;
    margin: 0 0 1rem;
    font-weight: 600;
    text-align: center; }
  .info-container__content h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 1.5rem 0 1rem;
    text-align: center; }
  .info-container__content h3 {
    font-size: 24px;
    font-weight: 600;
    margin: 1rem 0;
    text-align: center; }
  .info-container__content h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 1rem 0;
    text-align: center; }
  .info-container__content p {
    line-height: 1.4;
    margin: 1rem 0 0; }
    .info-container__content p a {
      display: inline;
      border-bottom: 1px solid; }
  .info-container__content ul,
  .info-container__content ol {
    list-style: disc;
    margin: 1rem 0 0 2rem; }
  .info-container__content li {
    margin: .5rem 0 0; }
  .info-container__content img {
    display: none; }
  .info-container__content_about h3 {
    font-size: 20px; }
  .info-container__subpage-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .25rem 0; }
    .info-container__subpage-list a {
      display: initial;
      border-bottom: 1px solid; }
  .info-container__subpage-info {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid;
    display: flex;
    gap: 0 1rem;
    justify-content: center; }
    .info-container__subpage-info a {
      border-bottom: 1px solid; }

.page {
  margin: 2rem 0 0; }
  .page__title {
    font-size: 34px;
    margin: 0 0 2rem;
    font-weight: 600;
    text-align: center; }
  .page__content h2 {
    font-size: 28px;
    font-weight: 600;
    margin: 1.5rem 0;
    text-align: center; }
  .page__content h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 1rem 0;
    text-align: center; }
  .page__content p {
    line-height: 1.4;
    margin: 1rem 0 0; }
    .page__content p a {
      display: inline;
      border-bottom: 1px solid; }
  .page__content ul,
  .page__content ol {
    list-style: disc;
    margin: 1rem 0 0 2rem; }
  .page__content li {
    margin: .5rem 0 0; }
  .page_error {
    text-align: center; }

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; }
  .pagination .current {
    border: 1px solid;
    padding: 0 .5rem; }
  .pagination .dots {
    border: none; }

.footer {
  border-top: 1px solid #e1e4e9;
  margin-top: 6rem;
  padding: 1.5rem 0; }
  .footer__container {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 1rem;
    max-width: 1200px;
    flex-direction: column;
    gap: 1.5rem; }
  .footer__nav-list {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    gap: 1rem 2rem; }
    .footer__nav-list a {
      color: #000;
      align-items: center;
      gap: .5rem; }
      .footer__nav-list a:hover {
        color: #000; }
  .footer__logo {
    text-align: center;
    user-select: none; }
    .footer__logo a {
      font-family: 'SansSerifBldFLF', sans-serif;
      font-size: 22px;
      line-height: .75;
      flex-direction: column; }

.content {
  padding: 20px;
  width: 75%; }
  .content__title {
    font-size: 2rem;
    margin: 0 0 1rem; }
  .content p {
    margin: 10px 0; }

.subpage__container {
  display: flex;
  justify-content: center; }

.subpage__content {
  max-width: 900px;
  margin-top: 2rem; }
  .subpage__content-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
    gap: 3.5rem 0; }
    .subpage__content-list li {
      flex-basis: calc(33.33% - 3.5rem); }
      .subpage__content-list li a {
        font-size: 20px;
        flex-direction: column;
        gap: .5rem; }
      .subpage__content-list li span {
        font-weight: 600; }
    .subpage__content-list .fa-light {
      font-size: 34px; }

.options .options__item a {
  color: #000;
  border-bottom: 0 !important; }
  .options .options__item a .fa-light.fa-toggle-on {
    color: #000; }

.options .options__content-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: .5rem 1rem; }
  .options .options__content-list span {
    cursor: pointer; }
    .options .options__content-list span:hover {
      opacity: 0.7; }

.fa-toggle-on::before {
  color: #000; }

#tradingview_1fb38 {
  height: 100vh;
  overflow-y: hidden; }

.embed .header {
  display: none; }

.embed .footer {
  display: none; }

.embed .wrapper {
  height: auto !important; }

.embed .nav {
  padding: 1rem 0 1rem;
  margin: 0; }

.embed .container {
  width: 100%; }

.embed__link {
  display: flex;
  justify-content: center;
  padding: 2rem 0 1rem;
  margin: 0; }
  .embed__link a {
    font-size: 18px;
    color: #858995;
    align-items: center;
    gap: .5rem; }

.info-block {
  width: 100%; }
  .info-block__control {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    margin: 3rem 0 0;
    gap: 1rem; }
    .info-block__control a, .info-block__control button {
      font-size: 18px;
      text-decoration: underline;
      text-decoration-style: dotted;
      text-decoration-thickness: 1px;
      text-underline-offset: 5.5px; }
  .info-block__data {
    display: flex;
    gap: .5rem 1rem;
    flex-wrap: wrap;
    justify-content: center; }
    .info-block__data-item {
      display: flex;
      gap: 5.25px; }
    .info-block__data-team {
      display: flex; }
      .info-block__data-team a {
        border-bottom: 1px solid; }
  .info-block__info {
    display: flex;
    gap: 1rem; }
    .info-block__info-item {
      display: flex; }
  .info-block__source {
    width: 100%;
    padding: .5rem;
    height: 12rem;
    border: 1px solid;
    overflow: auto;
    margin: .5rem 0; }
    .info-block__source ul {
      margin: 0 !important; }
    .info-block__source li {
      list-style: none !important; }
      .info-block__source li:last-of-type {
        margin-bottom: 0 !important; }
    .info-block__source-title {
      margin-bottom: .5rem;
      color: #cdcdcd;
      border-bottom: 1px solid #cdcdcd; }

:fullscreen .header,
:fullscreen .container__title,
:fullscreen .container__2x_top,
:fullscreen .info-block,
:fullscreen .info-container,
:fullscreen .footer {
  display: none; }

:fullscreen .nav {
  margin: 1rem 0 1rem; }

:fullscreen .container {
  width: 100%; }

.solitaire {
  margin: auto !important; }

.dark-theme {
  background: #000;
  color: #fff; }
  .dark-theme a {
    color: #fff; }
    .dark-theme a:hover {
      opacity: 0.85 !important; }
    .dark-theme a:visited {
      color: #fff; }
    .dark-theme a.inactive:hover {
      opacity: 0.85; }
  .dark-theme button:hover {
    opacity: 0.85; }
  .dark-theme ::selection {
    background: #fff;
    color: #000; }
  .dark-theme .header {
    border-color: rgba(224, 224, 224, 0.2); }
    .dark-theme .header__logo a {
      color: #fff; }
    .dark-theme .header__nav-list a {
      color: #fff; }
    .dark-theme .header__lang-list a {
      color: #fff; }
    .dark-theme .header__lang-list_inner li {
      color: #fff; }
    .dark-theme .header__lang-list_inner span:hover {
      opacity: 0.85; }
    .dark-theme .header__menu-toggle:before {
      color: #fff; }
    .dark-theme .header__menu-toggle:hover {
      opacity: 0.85; }
    .dark-theme .header__toggle .toggle:checked + label:after {
      color: #fff; }
    .dark-theme .header__toggle .toggle:not(checked) + label {
      color: #fff; }
      .dark-theme .header__toggle .toggle:not(checked) + label:hover {
        opacity: 0.85; }
  .dark-theme .nav__content-info {
    color: #fff; }
    .dark-theme .nav__content-info_block a {
      color: #fff; }
    .dark-theme .nav__content-info_muted {
      color: #fff; }
      .dark-theme .nav__content-info_muted:not([disabled]):hover {
        color: #fff; }
  .dark-theme .nav__options .options__content {
    color: #fff; }
  .dark-theme .nav__options .options__title {
    color: #fff; }
  .dark-theme .nav__options .options__close:hover {
    opacity: 0.85; }
  .dark-theme .nav__options.open-submenu .nav__options-overlay {
    background-color: black; }
  .dark-theme .submenu__list {
    background-color: #000; }
  .dark-theme .cookie {
    background-color: #000; }
  .dark-theme .modal__close:hover {
    opacity: 0.85; }
  .dark-theme .modal__wrap {
    background-color: #000; }
  .dark-theme .modal__header-title {
    color: #fff; }
  .dark-theme .modal__content-custom_input {
    color: #fff;
    background: #000; }
  .dark-theme .modal__content-item_caption a {
    color: #a8a8a8; }
  .dark-theme .modal__content img {
    filter: invert(95%); }
  .dark-theme .modal__content textarea {
    border-color: #fff;
    color: #fff;
    background: #000; }
  .dark-theme .modal__footer-button_primary {
    color: #fff; }
  .dark-theme .modal__footer-button:hover {
    opacity: 0.85; }
  .dark-theme .modal__overlay {
    background: black; }
  .dark-theme .options__item a {
    color: #fff; }
    .dark-theme .options__item a .fa-light.fa-toggle-on {
      color: #fff; }
  .dark-theme .close__submenu:before,
  .dark-theme .modal__close:before,
  .dark-theme .cookie__close:before,
  .dark-theme .options__close:before,
  .dark-theme .notification__close:before {
    color: #fff; }
  .dark-theme .modal__content,
  .dark-theme .cookie__content p a {
    color: #fff; }
  .dark-theme .game .game__info {
    color: #fff; }
  .dark-theme .game__content {
    background: #000; }
  .dark-theme .widget__field {
    background: #38393a; }
    .dark-theme .widget__field textarea {
      background: #38393a;
      color: #fff; }
  .dark-theme .widget__input {
    background: #38393a; }
    .dark-theme .widget__input-number {
      background: #38393a;
      color: #fff; }
    .dark-theme .widget__input-text {
      background: #38393a;
      color: #fff; }
    .dark-theme .widget__input-image {
      filter: invert(88%);
      mix-blend-mode: luminosity; }
  .dark-theme .widget__button button {
    color: #fff; }
    .dark-theme .widget__button button:hover {
      opacity: 0.85; }
  .dark-theme .widget__submenu-list a span {
    color: #fff; }
  .dark-theme .widget__toggle label {
    color: #fff; }
    .dark-theme .widget__toggle label:hover {
      opacity: 0.85; }
  .dark-theme .widget__options-list {
    color: #fff; }
  .dark-theme .widget__table {
    background: #000; }
  .dark-theme .widget__result-field textarea {
    background: #000;
    color: #fff;
    border-color: #fff; }
  .dark-theme .widget__result-text {
    background: #000; }
    .dark-theme .widget__result-text img {
      filter: invert(100%);
      mix-blend-mode: normal; }
  .dark-theme .widget__result-table {
    background: #000; }
  .dark-theme .widget__result-list {
    background: #000; }
    .dark-theme .widget__result-list a {
      color: #10d100; }
  .dark-theme .widget__result-chart {
    background: #000; }
  .dark-theme .widget__result-tab > input[type="radio"]:checked + label {
    opacity: 1; }
  .dark-theme .widget__result-tab > label {
    color: #fff; }
    .dark-theme .widget__result-tab > label:hover {
      opacity: 0.85; }
  .dark-theme .widget__result b, .dark-theme .widget__result strong {
    color: #fff; }
  .dark-theme .page {
    color: #fff; }
  .dark-theme .subpage__content-list .fa-light {
    color: #fff; }
  .dark-theme .footer {
    border-color: rgba(224, 224, 224, 0.2); }
    .dark-theme .footer__nav-list a {
      color: #fff; }
    .dark-theme .footer__container {
      color: #fff; }
    .dark-theme .footer__logo {
      color: #fff; }
  .dark-theme .toggle-button a {
    color: #a8a8a8; }
  .dark-theme .info-block__source-title {
    color: #777;
    border-color: #777; }
  .dark-theme .info-container {
    background-color: #38393a; }
  .dark-theme .moment-picker .moment-picker-container {
    background-color: #000; }
  .dark-theme .moment-picker td.selected {
    color: #fff;
    background-color: #38393a; }
  .dark-theme .moment-picker th.disabled,
  .dark-theme .moment-picker td.disabled,
  .dark-theme .moment-picker th.disabled:hover,
  .dark-theme .moment-picker td.disabled:hover {
    color: #a8a8a8; }
  .dark-theme input::placeholder,
  .dark-theme textarea::placeholder {
    color: rgba(255, 255, 255, 0.6); }
  .dark-theme .selected {
    background: #38393a; }
  .dark-theme .rzslider .rz-bar {
    background: #fff; }
  .dark-theme .rzslider .rz-pointer {
    background-color: #000;
    border-color: #fff; }
  @media (max-width: 961px) {
    .dark-theme .header__nav {
      background-color: black; }
      .dark-theme .header__nav-list {
        background-color: #000; } }

.component-modal:not(.component-modal-forced):not(:last-of-type) .modal {
  display: none; }

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  user-select: none; }
  .modal.open-modal .modal__overlay, .modal.open-modal {
    display: block;
    z-index: 105; }
  .modal__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    display: none; }
  .modal__close {
    position: absolute;
    align-items: baseline;
    margin: 0;
    top: 0;
    right: 21px; }
    .modal__close:hover {
      opacity: 0.7; }
  .modal.open-modal .modal__wrap {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; }
  .modal__wrap {
    display: none;
    box-shadow: none;
    z-index: 106;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    width: 800px; }
    .modal__wrap_switch {
      display: flex;
      flex-direction: column;
      gap: 1.5rem; }
    .modal__wrap_info {
      max-width: 800px; }
      .modal__wrap_info .modal__content {
        align-items: start;
        justify-content: start;
        flex-direction: column;
        gap: .5rem; }
        .modal__wrap_info .modal__content li:last-of-type {
          margin-bottom: 0; }
  .modal__header {
    margin-top: 1.5rem; }
    .modal__header-title {
      font-size: 28px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-wrap: wrap;
      gap: .5rem; }
      .modal__header-title::first-letter {
        text-transform: uppercase; }
      .modal__header-title_major {
        font-size: 34px; }
  .modal__content {
    overflow: auto;
    max-block-size: calc(100% - 1.5rem);
    color: #000;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem; }
    .modal__content-right {
      text-align: right; }
    .modal__content p {
      margin: .5rem 0 0;
      line-height: 1.4; }
      .modal__content p a {
        border-bottom: 1px solid;
        display: inline; }
    .modal__content ul {
      margin: .5rem 0 0 2rem; }
    .modal__content li {
      list-style: disc;
      margin-bottom: .5rem;
      line-height: 1.4; }
    .modal__content h2,
    .modal__content h3 {
      margin: .5rem 0 0;
      font-size: 28px;
      font-weight: 600; }
    .modal__content h4 {
      margin: .5rem 0 0;
      font-size: 20px;
      font-weight: 600; }
    .modal__content img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 16px; }
    .modal__content textarea {
      padding: .5rem;
      resize: none;
      width: 100%;
      height: 12rem;
      display: block;
      border: 1px solid #000;
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      color: #000; }
    .modal__content-item_textarea {
      width: 100%; }
    .modal__content-item_caption {
      font-size: 16px;
      margin-top: .5rem;
      display: flex;
      justify-content: center;
      text-align: center; }
      .modal__content-item_caption a {
        color: #858995; }
  .modal__footer {
    display: flex;
    justify-content: center; }
    .modal__footer-col {
      display: flex;
      align-items: center; }
    .modal__footer-buttons {
      display: flex;
      align-items: baseline;
      gap: 1.5rem; }
      .modal__footer-buttons.modal__footer-buttons_cols {
        justify-content: space-between; }
    .modal__footer-button {
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: 600; }
      .modal__footer-button:hover {
        opacity: 0.7; }
      .modal__footer-button_primary {
        color: #000;
        background: transparent;
        border: solid;
        border-width: 1.5px;
        white-space: nowrap;
        touch-action: manipulation;
        padding: .5rem 1rem;
        border-radius: 3rem; }

body.has-modal-global-scroll {
  overflow: hidden; }

.modal.modal-global-scroll.open-modal .modal__overlay {
  display: flex; }

.modal.modal-global-scroll .modal__overlay {
  overflow-x: hidden;
  align-items: flex-start;
  height: 100%;
  justify-content: center;
  top: 0;
  width: 100%; }

.modal.modal-global-scroll .modal__wrap {
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  margin: 1rem 1rem 2rem; }

.modal.modal-global-scroll .modal__content {
  overflow: hidden;
  max-height: unset; }

.modal .options__item {
  display: flex;
  align-items: center;
  margin: 1rem 0; }
  .modal .options__item label {
    margin: 0 1rem 0 0; }

.rtl .header__logo a {
  direction: ltr; }

.rtl .header__toggle .toggle:not(checked) + label {
  padding: 0; }

.rtl .nav__options .options {
  left: 50%; }

.rtl .nav__options .options__close {
  left: 21px;
  right: initial; }

.rtl .submenu__list {
  right: initial; }

.rtl .modal__close {
  left: 21px;
  right: initial; }

.rtl .modal__content ul {
  margin: .5rem 2rem 0 0; }

.rtl .modal #embed-textarea {
  direction: ltr; }

.rtl .footer__logo a {
  direction: ltr; }

.rtl .checkbox:not(checked) + label:before, .rtl .checkbox:not(checked) + label:after {
  right: 0;
  left: initial; }

.rtl .toggle:not(checked) + label {
  padding: 0 28px 0 0; }
  .rtl .toggle:not(checked) + label:before {
    left: initial;
    right: 0; }
  .rtl .toggle:not(checked) + label:after {
    left: initial;
    right: 0; }

@media (max-width: 1200px) {
  .container {
    width: 100%;
    padding: 0 1rem; }
  .content {
    width: 100%;
    padding: 20px 0; }
  .header__container,
  .footer__container {
    width: 100%;
    padding: 0 1rem; } }

@media (max-width: 961px) {
  .header.open-menu .header__nav {
    display: block; }
  .header.open-menu .header__menu-toggle {
    position: fixed;
    margin: 0;
    z-index: 104;
    right: 1rem; }
    .header.open-menu .header__menu-toggle:before {
      content: "\f00d"; }
  .submenu__list-column ul {
    gap: 1rem; }
  .nav__options .options {
    width: calc(100% - 2rem); }
  .nav__content-list li.nav__options > a span {
    display: none; }
  .modal__wrap {
    width: calc(100% - 2rem); }
    .modal__wrap_info {
      max-width: calc(100% - 2rem); }
  .subpage__content-list li {
    flex-basis: calc(50% - 0rem); } }

@media (max-width: 768px) {
  .modal__content ul {
    margin: 1rem 0 0 1rem; } }

[select-directive].settimer__label.settimer__label_select {
  height: calc(32px + 32px); }

[select-directive] {
  height: 32px; }

.select {
  position: absolute;
  background-color: white; }
  .select__main {
    position: relative;
    display: inline-block;
    width: 400px;
    line-height: 30px; }
  .select__baseline {
    position: relative;
    display: inline-block;
    overflow: visible;
    width: 100%;
    vertical-align: top;
    font: inherit; }
  .select__search {
    color: #000;
    padding: 0 25px 0 8px;
    font: inherit;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    border: 0;
    outline: 0;
    background: transparent;
    box-shadow: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent; }
    .select__search:focus ~ .select__styling {
      border: 1px solid #000;
      box-shadow: inset 0 0 0 1px #000; }
    .select__search:focus ~ .select__arrow:before {
      transform: rotate(-180deg) translateY(-50%); }
  .select__control {
    display: block;
    overflow: hidden;
    width: 100%;
    text-align: left;
    text-overflow: ellipsis; }
    .select__control:after {
      content: "\A0"; }
    .select__control.select__styling {
      line-height: 30px;
      padding: 0 14px;
      box-sizing: border-box;
      cursor: pointer;
      user-select: none;
      white-space: nowrap;
      text-decoration: none;
      border-radius: 3px;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      transition: border 0.1s ease-out, box-shadow 0.1s ease-out; }
  .select__arrow {
    width: 35px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    text-align: center; }
    .select__arrow:before {
      transition: transform 0.1s ease-out;
      display: block;
      top: 50%;
      transform: translateY(50%); }
  .select__menu {
    top: calc(100% + 8px);
    max-height: 236px;
    padding: 5px 0;
    position: absolute;
    left: 0;
    overflow-y: auto;
    min-width: 100%;
    background-color: white;
    z-index: 105; }
    .select__menu.select__menu_active {
      display: block; }
    .select__menu-item {
      display: block;
      overflow-x: hidden;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 15px 0 34px;
      margin-top: 0;
      border-top: none; }
      .select__menu-item[role="option"].focused {
        background-color: #00c1ff; }
      .select__menu-item_active {
        background-color: #00c1ff; }
      .select__menu-item:not([disabled]):hover {
        background-color: #00c1ff; }
      .select__menu-item_not_found {
        color: lightgray; }
    .select__menu-item_not_found {
      display: none; }
    .select__menu-item_not_found:first-child {
      display: block; }

.loading {
  text-align: center;
  transition: all linear .5s;
  opacity: 1; }

.loading.ng-hide-add-active {
  opacity: 0; }

@keyframes animation-name {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.hide-loading {
  opacity: 1; }
  .hide-loading.ng-hide-remove-active {
    animation-name: animation-name;
    animation-duration: .1s;
    animation-delay: .6s;
    animation-timing-function: ease-in;
    animation-direction: alternate; }
  .hide-loading.ng-hide-animate {
    opacity: 0; }
