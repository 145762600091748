@import 'variables';

.dark-theme {
  background: $dark-color2;
  color: $dark-color;

  a {
    color: $dark-color;

	  &:hover {
	    opacity: $dark-opacity !important;
	  }

	  &:visited {
	    color: $dark-color;
	  }

	  &.inactive {
	    &:hover {
	      opacity: $dark-opacity;
	    }
	  }
  }

	button:hover {
		opacity: $dark-opacity;
	}

  ::selection {
    background: $dark-color;
    color: $dark-color2;
  }

  .header {
    border-color: rgba(#e0e0e0, .2);

	  &__logo {
	    a {
		    color: $dark-color;
		  }
	  }

	  &__nav-list a {
	    color: $dark-color;
	  }

	  &__lang-list {
		  a {
		    color: $dark-color;
		  }

		  &_inner {
		    li {
		      color: $dark-color;
		    }

		    span:hover {
		      opacity: $dark-opacity;
		    }
		  }
	  }

	  &__menu-toggle {
			&:before {
		    color: $dark-color;
		  }

			&:hover {
				opacity: $dark-opacity;
			}
	  }

	  &__toggle .toggle:checked + label:after {
      color: $dark-color;
		}

		&__toggle .toggle:not(checked) + label {
			color: $dark-color;

			&:hover {
				opacity: $dark-opacity;
			}
		}
  }

  .nav__content-info {
    color: $dark-color;

	  &_block a {
	    color: $dark-color;
	  }

		&_muted {
			color: $dark-color;

			&:not([disabled]):hover {
		    color: $dark-color;
		  }
		}
  }

	.nav {
	  &__options {
			.options {
			  &__content {
			    color: $dark-color;
			  }

			  &__title {
		      color: $dark-color;
		    }

	      &__close {
	        &:hover {
	          opacity: $dark-opacity;
	        }
	      }
		  }

		  &.open-submenu {
				.nav__options-overlay {
		      background-color: $dark-background;
		    }
		  }
	  }
	}

  .submenu__list {
    background-color: $dark-color2;
  }

  .cookie {
    background-color: $dark-color2;
  }

	.modal {
	  &__close:hover {
	    opacity: $dark-opacity;
	  }

	  &__wrap {
	    background-color: $dark-color2;
	  }

	  &__header {
		  &-title {
		    color: $dark-color;
		  }
	  }

	  &__content {
		  &-custom_input {
		    color: $dark-color;
		    background: $dark-color2;
		  }

		  &-item_caption a {
		    color: $dark-color4;
		  }

		  img {
		    filter: invert(95%);
		  }

		  textarea {
		    border-color: $dark-color;
        color: $dark-color;
        background: $dark-color2;
		  }
	  }

		&__footer-button {
			&_primary {
				color: $dark-color;
			}

			&:hover {
				opacity: $dark-opacity;
			}
		}

		&__overlay {
			background: $dark-background;
		}
	}

	.options {
		&__item a {
      color: $dark-color;

	    .fa-light.fa-toggle-on {
	      color: $dark-color;
	    }
	  }
	}

  .close__submenu:before,
  .modal__close:before,
  .cookie__close:before,
  .options__close:before,
  .notification__close:before {
    color: $dark-color;
  }

  .modal__content,
  .cookie__content p a {
    color: $dark-color;
  }

	.game {
		.game__info {
			color: $dark-color;
		}

		&__content {
			background: $dark-color2;
		}
	}

	.widget {
		&__field {
			background: $dark-color6;

			textarea {
				background: $dark-color6;
				color: $dark-color;
			}
		}

		&__input {
			background: $dark-color6;

			&-number {
				background: $dark-color6;
				color: $dark-color;
			}

			&-text {
				background: $dark-color6;
				color: $dark-color;
			}

			&-image {
				filter: invert(88%);
        mix-blend-mode: luminosity;
			}
		}

		&__button button {
			color: $dark-color;

			&:hover {
				opacity: $dark-opacity;
			}
		}

		&__submenu-list a span {
			color: $dark-color;
		}

		&__toggle label {
			color: $dark-color;

			&:hover {
				opacity: $dark-opacity;
			}
		}

		&__options-list {
			color: $dark-color;
		}

		&__table {
			background: $dark-color2;
		}

		&__result {
			&-field {
				textarea {
					background: $dark-color2;
					color: $dark-color;
					border-color: $dark-color;
				}
			}

			&-text {
				background: $dark-color2;

				img {
					filter: invert(100%);
					mix-blend-mode: normal;
				}
			}

			&-table {
				background: $dark-color2;
			}

			&-list {
				background: $dark-color2;

				a {
					color: $dark-color9;
				}
			}

			&-chart {
				background: $dark-color2;
			}

			&-tab {
	      & > input[type="radio"]:checked + label {
	        opacity: 1;
	      }

				& > label {
					color: $dark-color;

					&:hover {
						opacity: $dark-opacity;
					}
				}
			}

			b, strong {
				color: $dark-color;
			}
		}
	}

	.page {
		color: $dark-color;
	}

	.subpage {
		&__content-list {
			.fa-light {
				color: $dark-color;
			}
		}
	}

  .footer {
    border-color: rgba(#e0e0e0, .2);

	  &__nav-list a {
	    color: $dark-color;
	  }

	  &__container {
	    color: $dark-color;
	  }

	  &__logo {
	    color: $dark-color;
	  }
  }

	.toggle-button a {
		color: $dark-color4;
	}

	.info-block {
		&__control a {
			//color: $dark-color4;
		}

		&__source-title {
	    color: $dark-color5;
	    border-color: $dark-color5;
		}
	}

	.info-container {
		background-color: $dark-color6;
	}

	.moment-picker {
		.moment-picker-container {
			background-color: $dark-color2;
		}

		td.selected {
      color: $dark-color;
      background-color: $dark-color6;
		}
	}

	.moment-picker th.disabled,
	.moment-picker td.disabled,
	.moment-picker th.disabled:hover,
	.moment-picker td.disabled:hover {
	  color: $dark-color4;
	}

	input::placeholder,
	textarea::placeholder {
	  color: rgba($color2, .6);
	}

	.selected {
    background: $dark-color6;
	}

	.rzslider {
		.rz-bar {
	    background: $dark-color;
		}

		.rz-pointer {
			background-color: $dark-color2;
		  border-color: $dark-color;
		}
	}

	@media (max-width: 961px) {
		.header__nav {
			background-color: $dark-background;

			&-list {
				background-color: $dark-color2;
			}
		}
	}
}